<template>
  <div
    class="category u-inline-block u-black t-h4 t-bold u-relative u-z-back"
    :class="{'is-active': active, 'u-cursor-pointer': click}"
  >
    <div v-if="count !== 0" class="category__count u-row u-middle u-center">
      {{ count }}
    </div>
    <div class="category__item u-black" :class="{'is-count': count !== 0}">
      <div class="u-relative u-row u-middle">
        <div class="category__item__img u-marg-r-xs">
          <app-image :alt="name" v-if="image" class="u-wrapper-panel u-fit-contain" :id="image" />
        </div>
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#ffffff'
    },
    image: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: true
    },
    count: {
      type: Number,
      default: 0
    },
    click: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.category
  opacity .4
  transition opacity .3s linear

  &.is-active
    opacity 1

  &__item
    padding .5em 1.5em
    border-radius 30px
    overflow hidden
    position relative

    &.is-count
      padding .5em 1.5em .5em 3em
    
    &:after
      content ''
      position absolute
      top 0
      left 0
      height 100%
      width 100%
      z-index -1
      background-color: $colors.white

    &__img
      height 2em
      width 2em
      position relative


  &__count
    position absolute
    top calc(50% - 1.5em)
    left -.5em
    height 3em
    width 3em
    border-radius 100%
    background $colors.white
    box-shadow 0px 3px 15px rgba(0, 0, 0, 0.25)
    
    
</style>