<template>
  <div class="styleguide u-wrapper-panel u-overflow-y-scroll u-invisible-scroll">
    <div class="u-row u-pad-sm t-h1 u-bg-second-color u-main-color">
      COLORS
    </div>
    <div class="u-row u-full-width">
      <div class="u-w3of12 u-box-1by1 u-bg-main-color">
        <div class="t-text u-wrapper-panel u-black u-pad-sm">
          main-color
        </div>
      </div>
      <div class="u-w3of12 u-box-1by1 u-bg-second-color">
        <div class="t-text u-wrapper-panel u-white u-pad-sm">
          second-color
        </div>
      </div>
      <div class="u-w3of12 u-box-1by1 u-bg-white">
        <div class="t-text u-wrapper-panel u-black u-pad-sm">
          white
        </div>
      </div>
      <div class="u-w3of12 u-box-1by1 u-bg-black">
        <div class="t-text u-wrapper-panel u-white u-pad-sm">
          black
        </div>
      </div>
    </div>
    <div class="u-row u-pad-sm t-h1 u-bg-second-color u-main-color">
      TYPOGRAPHY
    </div>
    <div class="u-bg-main-color u-second-color">
      <div class="t-h1 u-pad-sm">
        t-h1
      </div>
      <div class="t-h2 u-pad-sm">
        t-h2
      </div>
      <div class="t-h3 u-pad-sm">
        t-h3
      </div>
      <div class="t-h4 u-pad-sm">
        t-h4
      </div>
      <div class="t-text u-pad-sm">
        t-text
      </div>
      <div class="t-cta u-pad-sm">
        t-cta
      </div>
    </div>
    <div class="u-row u-pad-sm t-h1 u-bg-second-color u-main-color">
      BUTTONS
    </div>
    <div class="u-row u-pad-sm u-bg-black u-second-color">
      <app-button class="u-marg-r-sm">
        App Button
      </app-button>
      <app-button :ghost="true" class="u-marg-r-sm">
        App Button Ghost
      </app-button>
      <app-button :main="true" class="u-marg-r-sm">
        App Button Main
      </app-button>
      <app-button :second="true" class="u-marg-r-sm">
        App Button Second
      </app-button>
    </div>
    <div class="u-row u-pad-sm t-h1 u-bg-second-color u-main-color">
      BUTTONS ROUND
    </div>
    <div class="u-row u-middle u-pad-sm u-bg-black u-second-color">
      <app-button-round svg="hand" class="u-marg-r-sm" />
      <app-button-round svg="hand" :big="true" class="u-marg-r-sm" />
      <app-button-round svg="hand" :small="true" class="u-marg-r-sm" />
      <app-button-round svg="hand" :ghost="true" class="u-marg-r-sm" />
      <app-button-round svg="hand" :white="true" class="u-marg-r-sm" />
      <app-button-round svg="hand" :invert="true" class="u-marg-r-sm" />
    </div>
    <div class="u-row u-pad-sm t-h1 u-bg-second-color u-main-color">
      APP ANSWER
    </div>
    <div class="u-row u-middle u-pad-sm u-bg-main-color u-second-color">
      <app-answer class="u-marg-r-sm" label="App Answer" />
      <app-answer :isTrue="true" label="App Answer" />
    </div>
    <div class="u-row u-pad-sm t-h1 u-bg-second-color u-main-color">
      ICONS
    </div>
    <div class="u-row u-pad-sm u-bg-main-color u-second-color">
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-stroke-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#plus"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#dialog"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#facebook"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#link"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#twitter"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#leave"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#glasses"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#home"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#zoom"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#hand"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-stroke-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#cross"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class=" u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#interrogation"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class="u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#exclamation"/>
          </svg>
        </div>
      </div>
      <div class="u-w1of12 u-box-1by1">
        <div class="u-wrapper-panel u-pad-sm">
          <svg class="u-fill-second-color u-full-height u-full-width u-fit-contain" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#external"/>
          </svg>
        </div>
      </div>
    </div>
    <div @click="isTuto = true" class="u-cursor-pointer u-row u-pad-sm t-h1 u-bg-second-color u-main-color">
      POPIN TUTO
    </div>
    <transition name="t-fader">
      <popin-tuto v-if="isTuto" @close="isTuto = false" :svg="hand" text="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam" cta="J’ai compris 🌞" />
    </transition>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'
import PopinTuto from '@/components/PopinTuto'
import AppAnswer from '@/components/common/AppAnswer'

export default {
  components: {
    AppButton,
    AppButtonRound,
    PopinTuto,
    AppAnswer
  },

  data () {
    return {
      isTuto: false
    }
  }
}
</script>
