<template>
  <app-image v-if="image" :id="image.id" :width="width" :height="height" :alt="image.alt" />
</template>

<script>

export default {
  computed: {
    image () {
      if (!this.images.length) {
        return null
      }

      return this.$device.isTablet && this.images[1] ? this.images[1] : this.images[0]
    }
  },

  props: {
    images: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  }
}
</script>