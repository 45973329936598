<template>
  <div class="question u-full-width u-relative u-pad-md u-overflow-y-scroll u-left u-invisible-scroll">

    <!-- QUESTION HEADER -->
    <div>
      <div class="u-bg-main-color u-second-color u-pad-y-xs u-pad-x-sm u-radius u-inline-block t-h4">
        Question {{ data.globalIndex + 1 }}
      </div>
    </div>
    <div class="t-h3 u-white u-marg-t-sm">
      {{ data.title }}
    </div>

    <!-- ANSWERS -->
    <div class="u-marg-t-sm">
      <app-answer
        class="u-full-width u-marg-t-sm u-z-back"
        :label="answer.label"
        :isTrue="answer.value"
        :name="data.title"
        v-for="answer in data.answers"
        :isSelecting="(!isValidate || isCategories) && selectedIndex(answer) >= 0"
        :isSelected="isValidate && !isCategories"
        @select="onSelect(answer)"
        v-show="!isAnswer || selectedIndex(answer) >= 0"
      />
    </div>

    <!-- CTA VALIDATE -->
    <!-- <div v-if="selectedAnswers.length && !isAnswer" class="u-row u-center">
      <app-button @click="onValidate" class="u-marg-t-md">
        Valider
      </app-button>
    </div> -->

    <!-- ANSWER TEXT -->
    <div v-if="isAnswer" class="question__answer-info u-relative ">
      <div class="t-text t-bold u-white u-relative u-marg-t-sm">
        {{ data.textWin }}
      </div>
      <div class="u-marg-t-sm u-row u-center">
        <app-button :main="true" @click="$emit('next')">
          Continuer
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppAnswer from '@/components/common/AppAnswer'
import AppButton from '@/components/common/AppButton'

export default {
  components: {
    AppAnswer,
    AppButton
  },

  computed: {
    goodAnswers () {
      return this.data.answers.filter(answer => !!answer.value)
    },
    isCategories () {
      return !!this.data.answers.filter(answer => !!answer.categories.length).length
    },
    isQCM () {
      return this.goodAnswers.length > 1
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    }
  },

  data () {
    return {
      selectedAnswers: [],
      isValidate: false,
      isAnswer: false,
      isRight: false
    }
  },

  methods: {
    onSelect (answer) {
      const index = this.selectedIndex(answer)

      if (this.isQCM) {
        if (index < 0) {
          this.selectedAnswers.push(answer)
        } else {
          this.selectedAnswers.splice(index, 1)
        }
      }
      else {
        if (index < 0) {
          this.selectedAnswers.splice(0, this.selectedAnswers.length)
          this.selectedAnswers.push(answer)
        }
      }

      this.onValidate()
    },

    onValidate () {
      if (this.isValidate) { return }

      this.isValidate = true

      this.isRight = arrayEquals(this.goodAnswers, this.selectedAnswers)
      this.$emit('answer', {right: this.isRight, answers: this.selectedAnswers })

      if (!this.isCategories) {
        if (this.isRight) {
          this.$ga.send('gameplay', 'answerTrue', this.data.slug)
        }
        else {
          this.$ga.send('gameplay', 'answerFalse', this.data.slug)
        }
      }
      setTimeout(this.onAnswer, 1500)
    },

    onAnswer () {
      this.isAnswer = true
    },

    selectedIndex (answer) {
      return this.selectedAnswers.findIndex(selected => selected.index === answer.index)
    },

    onNext () {
      this.$ga.send('gameplay', 'continuer')
      this.$emit('next')
    }
  }
}

function arrayEquals(a, b) {
  a.sort((c, d) => { return c.index < d.index ? -1 : 1 })
  b.sort((c, d) => { return c.index < d.index ? -1 : 1 })
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val.index === b[index].index);
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

$second-color = #011449

.question
  max-height 100%
  border-radius 20px
  background-color: rgba($colors.white, .2)

  +mq($until: 'desktop')
    background-color: rgba($second-color, .4)
  
  &__answer-info
    +mq($from: 'desktop')
      margin-top -30px
      padding 30px 20px 20px

    &:before
      content ''
      opacity .2
      position absolute
      height 100%
      width 100%
      left 0
      top 0
      border-radius 20px
      background-color $colors.white
      
      +mq($until: 'desktop')
        opacity 0

</style>