<template>
  <div class="article u-left u-bg-main-color u-radius u-relative" :class="{'u-pad-y-xl u-full-height': isFull, 'u-overflow-y-scroll u-invisible-scroll u-full-height u-pad-b-sm': isFull || !$device.isTablet}">
    <div v-if="isFull" class="article__close">
      <app-button-round @click="$emit('close')" class="u-marg-r-sm" svg="cross" :stroke="true" :ghost="true" />
    </div>
    <div class="js-titles">
      <div class="article__categories u-marg-b-md">
        <app-drag
          class="u-nowrap"
          :isScroll="!$device.isMobile"
          :class="isFull ? 'u-pad-l-1of12': 'u-pad-l-md'"
        >
          <div class="js-drag">
            <category
              v-for="category in data.categories"
              :name="category.title"
              color="#ff0000"
              :click="false"
              class="u-marg-r-sm js-drag-item"
              :image="category.image.length ? category.image[0].id : ''"
            />
          </div>
        </app-drag>
      </div>
      <div :class="isFull ? 'u-pad-x-1of12': 'u-pad-x-md'">
        <div class="article__titles">
          <div class="u-white" :class="isFull ? 't-h1': 't-h2'">
            {{data.title}}
          </div>
          <div class="u-white" :class="isFull ? 't-h2': 't-h3'">
            {{data.subtitle}}
          </div>
        </div>
      </div>
    </div>
    <div :class="isFull ? 'u-pad-x-1of12': 'u-pad-x-md'">
      <div v-if="!isFull" class="article__reading u-marg-t-md">
        <app-button @click="$emit('full')" :icon="true" :iconBig="true" :ghost="true" >
          <svg class="u-fill-white u-marg-r-sm" preserveAspectRatio="xMidYMid slice">
            <use xlink:href="#glasses"/>
          </svg>
          <div>
            MODE LECTURE
          </div>
        </app-button>
      </div>
      <div v-if="data.video?.length || data.image?.length" class="article__media u-radius u-overflow-hidden u-marg-t-md">
        <div v-if="data.video?.length" class="u-box-16by9">
          <video-player :src="data.video[0].url" />
        </div>
        <app-images
          v-else
          :images="data.image"
          :width="600"
          class="u-block u-full-width u-radius u-cursor-pointer"
        />
      </div>
      <div v-for="text in data.multipleTexts" class="t-wysiwyg u-white-space u-marg-t-sm u-white" v-html="text.text" />
    </div>
  </div>
</template>

<script>
import MediaType from '@/mixins/MediaType'

import VideoPlayer from '@/components/common/players/VideoPlayer'
import Category from '@/components/common/Category'
import AppDrag from '@/components/common/AppDrag'
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  mixins: [MediaType],

  components: {
    VideoPlayer,
    Category,
    AppDrag,
    AppButton,
    AppButtonRound
  },

  props: {
    isFull: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.article
  &__close
    position absolute
    top $spacings.md
    right $spacings.md
</style>