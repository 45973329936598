<template>
  <div class="u-wrapper-panel" @click="onClose">
    <transition :name="$device.isMobile ? 't-translate--up' : 't-translate--right'">
      <panel v-show="!transition" :data="data" />
    </transition>
    <!-- <transition name="t-fader">
      <router-link
        v-if="!$device.isTablet && !isTransition"
        :to="prevKeypoint"
        class="keypoint__button keypoint__button--left u-cursor-pointer u-bg-yellow"
      >
        <div class="u-full-width u-full-height">
          <svg class="u-fit-contain u-fill-green" >
            <use xlink:href="#arrow-left"/>
          </svg>
        </div>
      </router-link>
    </transition>
    <transition name="t-fader">
      <router-link
        v-if="!$device.isTablet && !isTransition"
        :to="nextKeypoint"
        class="keypoint__button keypoint__button--right u-cursor-pointer u-bg-yellow"
      >
        <div class="u-full-width u-full-height">
          <svg class="u-fit-contain u-fill-green" >
            <use xlink:href="#arrow-right"/>
          </svg>
        </div>
      </router-link>
    </transition> -->

    <!-- <transition name="t-fader">
      <popin :media="popinMedia" v-if="popinMedia" @close="popinMedia = ''" />
    </transition> -->
    <!-- <transition name="t-fader">
      <app-back-button :to="{...$local('Place'), params: { slug: entry.slug }}" v-if="!isTransition && !isPanelOpen" :name="entry.title" />
    </transition> -->
  </div>
</template>

<script>
import { Vector3, Box3 } from 'three'

import Panel from '@/components/Panel'

export default {
  components: {
    // AppBackButton,
    Panel
  },

  props: {
    data: {
      type: Object,
      default: () => { return {} }
    },
    transition: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      popinMedia: '',
      isPanelOpen: false
    }
  },

  mounted () {
    this.$ga.send('navigation', 'Keypoint', 'media', this.data.slug)
  },

  computed: {
    // nextKeypoint () {
    //   let target = this.entry.keypointsOnly[this.data.indexKeypoint+1]

    //   if (!target) {
    //     target = this.entry.keypointsOnly[0]
    //     return {
    //       ...this.$local('Keypoint'),
    //       params: {
    //         slug: this.entry.slug,
    //         place: target.slug
    //       }
    //     }
    //   }

    //   return {
    //     ...this.$local('Keypoint'),
    //     params: {
    //       slug: this.entry.slug,
    //       place: target.slug
    //     }
    //   }
    // },

    // prevKeypoint () {
    //   let target = this.entry.keypointsOnly[this.data.indexKeypoint-1]

    //   if (!target) {
    //     target = this.entry.keypointsOnly[this.entry.keypointsOnly.length - 1]
    //     return {
    //       ...this.$local('Keypoint'),
    //       params: {
    //         slug: this.entry.slug,
    //         place: target.slug
    //       }
    //     }
    //   }

    //   return {
    //     ...this.$local('Keypoint'),
    //     params: {
    //       slug: this.entry.slug,
    //       place: target.slug
    //     }
    //   }
    // }
  },

  mounted () {
    this.onInit()
  },

  methods: {
    onInit () {
      this.isPanelOpen = false
      this.$store.commit('data/completed', { keypoint: this.data })
      // this.$store.commit('data/isSeen', {indexPlace: this.entry.index, indexKeypoint: this.data.index})
    },
  },

  watch: {
    $route () {
      this.onInit()
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.keypoint
  &__button
    position absolute
    top 50%
    left 30px
    height 80px
    width 80px
    padding 23px
    border-radius 50%
    
    &--right
      left auto
      // 450 + 30
      right 480px

</style>