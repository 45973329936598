<template>
  <transition name="t-fader">
    <template v-if="!transition">
      <question :transition="transition" v-if="hasQuestions" :data="data" />
    </template>
  </transition>
</template>

<script>
import Kview from '@/views/keypoint/View'
import Media from '@/views/keypoint/Media'
import Question from '@/views/keypoint/Question'

import { Vector3, Box3 } from 'three'
import { webGL } from '@/webGL/WebGL'
import { FitTo, positionPadding } from 'shimmer'
import { setup } from '@/assets/data'

import { OrbitAuto } from 'shimmer'

import { audio } from '@/utils/Audio'

import gsap from 'gsap'

const zoomDist = {
  min: .2,
  max: 5
}


export default {
  components: {
    Kview,
    Media,
    Question
  },

  data () {
    return {
      transition: true
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/keypoint'](this.$route.params.super, this.$route.params.slug)
    },
    hasChildren () {
      return !!this.data?.keypoints?.length
    },
    hasQuestions () {
      return !!this.data?.questions?.length
    },
    type () {
      return this.data.questions.length ? 'question' : 'media'
    }
  },

  mounted () {
    this.orbit = new OrbitAuto(webGL.camera, null, Math.PI * .1, Math.PI * .1)
    this.onInit()
  },

  methods: {
    onInit () {
      this.transition = true

      this.orbit.active = false

      if (this.hasChildren) {
        this.superKeypointPlace()
      }
      else {
        this.keypointPlace()
      }
    },

    superKeypointPlace () {
      const box = new Box3().makeEmpty()
      this.data.keypoints.forEach(keypoint => {
        const pos = new Vector3(keypoint.position[0].x, keypoint.position[0].y, keypoint.position[0].z)
        box.expandByPoint(pos)
      })

      // if only one keypoint, we fake others keypoint 
      // right before and after
      // to prevent camera from behing stuck
      if (this.data.keypoints.length === 1) {
        const marg = .5
        box.expandByPoint(new Vector3(parseFloat(this.data.keypoints[0].position[0].x) - marg, parseFloat(this.data.keypoints[0].position[0].y) - marg, parseFloat(this.data.keypoints[0].position[0].z) - marg))
        box.expandByPoint(new Vector3(parseFloat(this.data.keypoints[0].position[0].x) + marg, parseFloat(this.data.keypoints[0].position[0].y) + marg, parseFloat(this.data.keypoints[0].position[0].z) + marg))
      }

      const look = new Vector3(this.data.look[0].x, this.data.look[0].y, this.data.look[0].z)
      const target = FitTo.fit( box, .6, { vector: look }, 'contain' )

      this.transitionTo(target)
    },

    keypointPlace () {
      const basePosition = new Vector3(parseFloat(this.data.position[0].x), parseFloat(this.data.position[0].y), parseFloat(this.data.position[0].z))
      const baseLook = new Vector3(parseFloat(this.data.look[0].x), parseFloat(this.data.look[0].y), parseFloat(this.data.look[0].z))
      const dist = zoomDist.min + (100 - this.data.zoom) * (zoomDist.max - zoomDist.min) * .01

      const target = positionPadding(basePosition, baseLook, dist, this.$device.isMobile ? 0 : .20)
      this.transitionTo(target)
    },

    transitionTo ({ position, look }) {
      this.tweenPosition = gsap.to(webGL.camera.position, {
        x: position.x,
        y: position.y,
        z: position.z,
        duration: setup.duration,
        ease: 'power2.inOut',
        onComplete: () => {
          setTimeout(() => {
            this.transition = false
          }, 200)
        }
      })

      this.tweenLook = gsap.to(webGL.camera.look, {
        x: look.x,
        y: look.y,
        z: look.z,
        duration: setup.duration,
        ease: 'power2.inOut',
        onUpdate: () => {
          webGL.camera.forceUpdateLook = true
        }
      })
    },

    onUpdate () {
      if (this.orbit) {
        this.orbit.update(this.$device.pointer.smooth)
      }
    }
  },

  beforeUnmount () {
    if (this.tweenPosition) {
      this.tweenPosition.kill()
      this.tweenLook.kill()
    }

    this.orbit.onDestroy()
  },

  watch: {
    $route (to, from) {

      if (this.tweenPosition) {
        this.tweenPosition.kill()
        this.tweenLook.kill()
      }

      if (to.name === 'Keypoint' || to.name === 'SubKeypoint') {
        this.onInit()
      }

      if (to.name === 'Keypoint') {
        audio.src = this.$store.state.global.sounds.keypoint
        audio.play()
      }
    },

    transition (to, from) {
      if(!this.$route.query.debug) {
        this.orbit.active = !this.transition
      }

      if (this.orbit.active) {
        this.$device.reinitSmoothPointer()
      }
    }
  }
}
</script>