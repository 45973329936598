<template>
  <div class="categories u-row u-middle u-uppercase">
    <div class="t-text u-white t-bold">
      {{ totalKeypoints }} Réalisations
      <div class="u-marg-t-sm">
        <div class="u-marg-t-sm" v-for="(category, index) in categories">
          <category
            @click="onToggle(index)"
            :name="category.title"
            color="#ff0000"
            :image="category.image.length ? category.image[0].id : ''"
            :active="!selectedCategories.length || getIsActive(category)"
            :count="category.count"
            v-if="category.count !== 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from '@/components/common/Category'

export default {
  components: {
    Category
  },

  computed: {
    totalKeypoints () {
      return this.$store.getters['data/totalKeypoints']
    },
    categories () {
      return this.$store.getters['data/categories']
    }
  },

  data () {
    return {
      selectedCategories: []
    }
  },

  methods: {
    onToggle (index) {
      const selectedIndex = this.selectedCategories.findIndex(selected => selected.id === this.categories[index].id)

      if (selectedIndex < 0) {
        this.selectedCategories.push(this.categories[index])
      } else {
        this.selectedCategories.splice(selectedIndex, 1)
      }

      this.$ga.send('navigation', 'filter', '', this.selectedCategories.map(cat => {return cat.slug}))
      this.$store.dispatch('data/filterKeypoints', this.selectedCategories)
    },

    getIsActive (cat) {
      return !!this.selectedCategories.find(category => category.id === cat.id)
    }
  }
}
</script>

<style lang="stylus">
// .categories
//   position absolute
//   top 0
//   left 0
//   height 100%
</style>