export default {
  methods: {
    mediaType (extension) {
      // temp
      if (extension.endsWith('mp4')) {
        return 'video'
      }
      else {
        return 'image'
      }
    }
  }
}