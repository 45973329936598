<template>
  <div class="keypoint-view u-wrapper-panel">
    <keypoint-switch class="keypoint-view__switch" />
    <template v-for="(keypoint, index) in keypoints">
      <keypoint
        :key="keypoint.slug"
        :to="getParams(keypoint.slug)"
        :position="keypoint.position[0]"
        :isComplete="keypoint.isComplete"
        v-if="keypoint.isActive"
      />
    </template>
  </div>
</template>

<script>
import Keypoint from '@/components/common/Keypoint'
import KeypointSwitch from '@/components/common/KeypointSwitch'

export default {
  props: {
    keypoints: {
      type: Object,
      default: () => {return {}}
    },
    slug: {
      type: String,
      default: ''
    },
    transition: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Keypoint,
    KeypointSwitch
  },

  mounted () {
    this.onInit()
  },

  methods: {
    onInit () {
      this.$ga.send('navigation', 'Keypoint', 'view', this.slug)
    },

    getParams (slug) {
      if (this.$route.params.super) {
        return {
          ...this.$local('SubKeypoint'),
          params: {
            super: this.$route.params.super,
            slug: slug
          }
        }
      }
      else {
        return {
          ...this.$local('Keypoint'),
          params: {
            slug: slug
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.keypoint-view
  &__switch
    position absolute
    top $spacings.md
    left 50%
    transform translateX(-50%)
</style>