<template>
  <app-popin name="t-fader">
    <div class="popin-mail u-radius u-pad-md">
      <div class="t-h3 u-center u-white">
        Pour découvrir les résultats, laissez votre email professionnel
      </div>
      <form class="u-left u-marg-t-sm u-marg-b-md" @submit.prevent="()=>{return}">
        <div v-if="err !== ''" class="popin-mail__err t-text u-marg-b-xs u-center">
          {{ err }}
        </div>
        <div class="u-row u-between">
          <div class="u-w7of12 u-w12of12--sm">
            <input class="popin-mail__input t-cta u-full-width" placeholder="email" type="mail" name="mail" v-model="mail">
          </div>
          <div class="u-w5of12 u-w12of12--sm u-pad-l-sm u-pad-l-0--sm u-marg-t-sm--sm">
            <app-button :class="{'is-active': mail !== '' && !isSending}" class="popin-mail__cta u-full-width" :main="true" @click="onValidate">
              VOIR RÉSULTAT
            </app-button>
          </div>
        </div>
      </form>
      <div class="t-text u-center u-white">
        En soumettant ce formulaire, vous acceptez <a class="popin-mail__link" href="https://brig.ht/condition-de-confidentialite" target="_blank">notre politique de confidentialité</a>.
      </div>
    </div>
  </app-popin>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import { hubspot } from '@/utils/Hubspot'


const domains = require('@/assets/free-domains.json') 

function validateMail (mail) {
  return String(mail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

function isProMail (mail) {
  let m = mail.split('@')
  m = m[m.length-1]

  return !domains.includes(m)
}

export default {
  components: {
    AppPopin
  },

  props: {
    categories: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      mail: '',
      err: '',
      isSending: false
    }
  },

  methods: {
    onValidate () {
      this.err = ''

      if (!validateMail(this.mail)) {
        this.err = 'L\'adresse mail n\'est pas valide.'
        return
      }

      if (!isProMail(this.mail)) {
        this.err = 'L\'adresse mail n\'est pas professionnelle.'
        return
      }

      this.isSending = true

      const res = hubspot.send({
        email: this.mail,
        bright_answer: this.categories.join()
      }).then(res => {
        this.$emit('next')
      }).catch(err => {
        this.err = err
        this.isSending = false
      })
    }
  }
}

</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

$second-color = #011449
$err-color = #e31010

.popin-mail
  background-color: rgba($colors.white, .3)

  +mq($until: 'desktop')
    background-color: rgba($colors.white, .3)

  &__err
    color $err-color

  &__input
    border solid 2px $second-color
    border-radius 35px
    padding 15px 25px
    background rgba($colors.white, .4)
  
  &__cta
    opacity .5
    transition opacity .3s linear
    pointer-events: none
    
    &.is-active
      opacity 1
      pointer-events: all
  
  &__link
    text-decoration underline
</style>