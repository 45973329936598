/* eslint-disable */

import { reactive, ref } from 'vue'
import { emitter } from '@/utils/emitter'

export default {
  install (app, options) {
    let analytics = {}
    analytics.isCreated = false
    analytics.isAllowed = false

    analytics.getGA = () => {
      if (window.gtag && analytics.isAllowed) {
        analytics.isCreated = true
        analytics.ga = window.gtag
      }
    }

    analytics.allowed = value => {
      analytics.isAllowed = true

      if (!analytics.isCreated) {
        return
      }
    }

    analytics.send = (eventAction, eventCategory, eventLabel, eventValue) => {
      if (eventAction === void 0 || eventCategory === void 0 || eventAction === void 0) {
        console.warn('eventCategory and eventAction needs to be defined')
        return
      }

      if (analytics.isAllowed && !analytics.isCreated) {
        setTimeout(() => {
          analytics.getGA()
          analytics.send(eventAction, eventCategory, eventLabel, eventValue)
        }, 200)
        return
      }

      if (!analytics.isAllowed) {
        return
      }

      if (!eventValue) {
        analytics.ga('event', eventAction, {
          'event_category': eventCategory,
          'event_label': eventLabel
        })
      } else {
        analytics.ga('event', eventAction, {
          'event_category': eventCategory,
          'event_label': eventLabel,
          'value': eventValue
        })
      }
    }

    emitter.on('analytics:enable', analytics.allowed)

    app.config.globalProperties.$ga = reactive(analytics)
  }
}