import { audio } from '@/utils/Audio'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  },

  computed: {
    isUrlAbsolute () {
      if (!this.to.name && this.url.startsWith('http')) {
        return true
      }

      return false
    }
  },

  mounted () {
    this.$el.addEventListener('click', this._onClick)
  },

  beforeUnmount () {
    this.$el.removeEventListener('click', this._onClick)
  },

  methods: {
    _onClick () {
      audio.src = this.$store.state.global.sounds.click
      audio.play()
    }
  }

}