<template>
  <nav class="keypoint-switch u-row u-between u-middle u-z-back">
    <router-link :to="prev" class="keypoint-switch__icon u-marg-r-sm">
      <svg class="u-wrapper-panel u-fill-transparent u-stroke-black" preserveAspectRatio="xMidYMid slice">
        <use xlink:href="#small-arrow-left"/>
      </svg>
    </router-link>
    <div class="t-h3 u-black">
      {{ data.title }}
    </div>
    <router-link :to="next" class="keypoint-switch__icon u-marg-l-sm">
      <svg class="u-wrapper-panel u-fill-transparent u-stroke-black" preserveAspectRatio="xMidYMid slice">
        <use xlink:href="#small-arrow-right"/>
      </svg>
    </router-link>
  </nav>
</template>

<script>
export default {
  computed: {
    data () {
      if (this.$route.name !== 'Keypoint' && this.$route.name !== 'SubKeypoint') {
        return null
      }
      return this.$store.getters['data/keypoint'](this.$route.params.super, this.$route.params.slug)
    },
    prev () {
      const prev = this.$store.getters['data/prevKeypoint'](this.$route.params.super, this.$route.params.slug)

      if (this.$route.name === 'Keypoint') {
        return { to: 'Keypoint', params: { super: prev.slug } }
      }
      else {
        return { to: 'SubKeypoint', params: { super: this.$route.params.super, slug: prev.slug } }
      }
    },
    next () {
      const next = this.$store.getters['data/nextKeypoint'](this.$route.params.super, this.$route.params.slug)

      if (this.$route.name === 'Keypoint') {
        return { to: 'Keypoint', params: { super: next.slug } }
      }
      else {
        return { to: 'SubKeypoint', params: { super: this.$route.params.super, slug: next.slug } }
      }
    },
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.keypoint-switch
  position relative
  background-color rgba($colors.white, .2)
  padding 10px 15px
  border-radius 30px

  &__icon
    position relative
    height 18px
    width 18px
</style>