<template>
  <div class="u-marg-t-sm" v-for="datum in data">
    <template v-if="datum.type === 'subTitle'">
      <h3 class="t-h3">{{ datum.title }}</h3>
    </template>
    <template v-else-if="datum.type === 'image'">
      <app-images class="u-full-width" :width="500" :images="datum.image" />
    </template>
    <template v-else-if="datum.type === 'text'">
      <div class="t-h4 u-full-width">
        {{ datum.text }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {return []}
    }
  }
}
</script>