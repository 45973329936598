<template>
  <div class="device-info u-pad-sm u-bg-white u-black u-cursor-pointer" @click="deviceInfo = !deviceInfo">
    <div class="device-info__title">
      Device Infos
    </div>
    <div v-if="deviceInfo" class="u-marg-t-md">
      <b>width</b>: {{ $device.width }} <br />
      <b>height</b>: {{ $device.height }} <br />
      <b>scroll</b>: {{ $device.scroll }} <br />
      <b>pixelRatio</b>: {{ $device.pixelRatio }} <br />
      <b>isMobile</b>: {{ $device.isMobile }} <br />
      <b>isTouch</b>: {{ $device.isTouch }} <br />
      <b>isIpad</b>: {{ $device.isIpad }} <br />
      <b>isIphone</b>: {{ $device.isIphone }} <br />
      <b>isAndroid</b>: {{ $device.isAndroid }} <br />
      <b>isFirefox</b>: {{ $device.isFirefox }} <br />
      <b>isChrome</b>: {{ $device.isChrome }} <br />
      <b>isSafari</b>: {{ $device.isSafari }} <br />
      <b>isTrident</b>: {{ $device.isTrident }} <br />
      <b>isEdge</b>: {{ $device.isEdge }} <br />
      <b>isMSIE</b>: {{ $device.isMSIE }} <br />
      <b>pointerEnabled</b>: {{ $device.pointerEnabled }} <br />
      <b>msPointer</b>: {{ $device.msPointer }} <br />
      <b>Programs</b>: {{ renderer.programs }} <br />
      <b>Geometries</b>: {{ renderer.geometries }} <br />
      <b>Textures</b>: {{ renderer.textures }} <br />
      <b>Drawcalls</b>: {{ renderer.drawcalls }} <br />
      <b>Triangles</b>: {{ renderer.triangles }} <br />
    </div>
  </div>
</template>

<script>
import { webGL } from '@/webGL/WebGL'

export default {
  data () {
    return {
      deviceInfo: false,
      renderer: {
        programs: webGL.renderer.renderer.info.programs.length,
        geometries: webGL.renderer.renderer.info.memory.geometries,
        textures: webGL.renderer.renderer.info.memory.textures,
        drawcalls: webGL.renderer.renderer.info.render.calls,
        triangles: webGL.renderer.renderer.info.render.triangles
      }
    }
  },

  methods: {
    onUpdate () {
      this.renderer.programs = webGL.renderer.renderer.info.programs.length
      this.renderer.geometries = webGL.renderer.renderer.info.memory.geometries
      this.renderer.textures = webGL.renderer.renderer.info.memory.textures
      this.renderer.drawcalls = webGL.renderer.renderer.info.render.calls
      this.renderer.triangles = webGL.renderer.renderer.info.render.triangles
    }
  }
}
</script>

<style lang="stylus">

.device-info
  position absolute
  bottom 0
  left 0
  border-radius 0 15px 0 0
  max-width 400px
  
  &__title
    font-size 1.2em
    font-weight bold
  
</style>