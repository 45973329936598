<template>
  <div class="introduction  u-main-color u-wrapper-panel u-flex u-column u-between u-pad-lg">
    <div class="u-full-width u-center u-relative">
      <img class="u-inline-block u-w1of12 u-w2of12--md u-w4of12--sm" src="/images/logo.png">
    </div>
    <div class="u-full-width u-relative">
      <div class="u-center">
        <div class="u-inline-block u-w8of12 u-w10of12--sm u-white" :class="$device.isMobile ? 't-h2' : 't-h1'">
          {{ data.title }}
        </div>
      </div>
      <div class="u-center u-marg-t-sm">
        <div class="u-inline-block u-w8of12 u-w10of12--sm" :class="$device.isMobile ? 't-h4' : 't-h2'">
          {{ data.subtitle }}
        </div>
      </div>
    </div>
    <div class="u-full-width u-center">
      <app-button
        :to="{name: 'Keypoint', params: {super: firstKeypoint.slug}}"
        @click="onStart"
        :white="true"
      >
        Commencer
      </app-button>
    </div>
  </div>
</template>

<script>
import Page from './Page'
import { audio } from '@/utils/Audio'

import { Vector3 } from 'three'

export default {
  mixins: [Page],

  computed: {
    data () {
      return this.$store.getters['pages/homepage']
    },
    firstKeypoint () {
      return this.$store.getters['data/keypoints'][0]
    },
    _position () {
      if (this.data.position.length) {
        return new Vector3(parseFloat(this.data.position[0].x), parseFloat(this.data.position[0].y), parseFloat(this.data.position[0].z))
      }
    },
    _look () {
      if (this.data.position.length) {
        return new Vector3(parseFloat(this.data.look[0].x), parseFloat(this.data.look[0].y), parseFloat(this.data.look[0].z))
      }
    }
  },

  methods: {
    onStart () {
      this.$ga.send('navigation', 'introduction', 'next')
      audio.src = this.$store.state.global.sounds.keypoint
      audio.play()
    }
  }
}  
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.introduction
  &:before
    content ''
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    background-color rgba($colors.black, .5)
</style>