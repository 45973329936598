<template>
  <div class="socials u-row u-middle" :class="'u-' + display">
    <a v-for="(social, index) in socials" :href="social.url" class="socials__item" :class="{'u-marg-l-sm': index !== 0}">
      <svg class="u-full-width u-full-height u-fill-main-color" preserveAspectRatio="xMidYMid slice">
        <use :xlink:href="'#' + social.icon"/>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: String,
      default: 'evenly'
    }
  },

  data () {
    return {
      socials: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/sharer/sharer.php?u=https://quiz-contenus.brig.ht/'
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          url: `https://twitter.com/share?url=https://quiz-contenus.brig.ht/&text=${encodeURIComponent('Grâce à ce quizz de Bright, découvrez quel format convient le mieux à votre prochain projet de communication.')}`
        },
        {
          name: 'Linkedin',
          icon: 'linkedin',
          url: 'https://linkedin.com/shareArticle/?url=https://quiz-contenus.brig.ht/'
        }
      ]
    }
  }
}
  
</script>

<style lang="stylus">
.socials
  &__item
    width 1.2em
    height 1.2em
</style>