<template>
  <div
    v-if="$route.name !== 'Homepage'"
    class="header u-between u-full-width u-row u-left u-middle u-pad-t-md u-pad-x-lg u-pad-x-md--sm u-main-color"
  >
    <div class="u-row u-middle u-w6of12"> <!-- :class="$route.params.slug ? 'u-w9of12': 'u-w3of12'" -->
      <router-link class="header__logo u-block" :to="{name: 'Homepage'}">
        <img src="/images/logo.png" class="u-full-width u-block">
      </router-link>
      <transition name="t-fader">
        <router-link :to="{name: 'Keypoint', params: {super: this.$route.params.super}}" v-if="$route.params.slug && superKeypoint" class="u-row u-middle u-pad-x-md u-block">
          <app-button-round class="u-marg-r-sm" svg="arrow-left" :small="true"/>
          <div v-if="$device.isDesktop" class="t-h3 t-bold">
            {{ superKeypoint.title }}
          </div>
        </router-link>
      </transition>
    </div>
    <!-- <div class="u-w6of12 u-center t-h2" v-if="!$route.params.slug">
      {{ titleHomepage }}
    </div> -->
    <div v-if="!$device.isDesktop" class="u-w6of12 u-row u-middle u-right">
      <app-button-round
        svg="menu"
        :stroke="true"
        :invert="true"
        @click="$emit('menu')"
      />
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound
  },

  computed: {
    superKeypoint () {
      return this.$store.getters['data/keypoint'](this.$route.params.super)
    },
    titleHomepage () {
      return this.$store.getters['pages/homepage'].title
    },
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.header
  position absolute
  top 0
  left 0

  &__logo
    width 100px

    +mq($until: 'tablet')
      width 60px

</style>