<template>
  <div>
    <transition name="t-fader">
      <template v-if="$device.isDesktop && $route.name !== 'Homepage'">
        <div class="bottom-links u-middle u-between u-row t-h4 u-main-color">
          <div class="u-row u-left u-w8of12">
            <div
              v-for="page in pages"
              @click="$emit('page', page.name)"
              class="t-h4 u-uppercase t-bold u-cursor-pointer u-marg-r-md"
            >
               {{ page.title }}
            </div>
          </div>
          <div class="u-row">
            <socials />
            <sound class="bottom-links__sound u-marg-l-sm" />
          </div>
        </div>
      </template>
      <template v-else-if="!$device.isDesktop && $route.name !== 'Homepage' && $route.name !== 'End'">
        <div class="bottom-links u-right u-row u-pointer-none">
          <sound class="bottom-links__sound u-pointer-all" />
        </div>
      </template>
    </transition>
    <!-- <template v-else-if="!$device.isDesktop && $route.name !== 'Introduction'">
      <div class="bottom-links u-row">
        <transition name="t-fader">
          <app-button-round v-if="$route.name === 'Homepage' " @click="isFilter = true" svg="filter" :invert="true" />
        </transition>
      </div>
    </template>
    <transition name="t-fader">
      <app-popin @close="isFilter = false" v-if="isFilter">
        <div class="u-bg-second-color u-radius u-pad-md u-relative">
          <categories />
        </div>
      </app-popin>
    </transition> -->
  </div>
</template>

<script>
import Socials from '@/components/common/Socials'
import Sound from '@/components/common/Sound'
import AppButtonRound from '@/components/common/AppButtonRound'
import AppPopin from '@/components/common/AppPopin'
import Categories from '@/components/Categories'

export default {
  computed: {
    pages () {
      return this.$store.getters['pages/annexes']
    }
  },

  data () {
    return {
      isFilter: false
    }
  },

  components: {
    Socials,
    AppPopin,
    AppButtonRound,
    Categories,
    Sound
  },

  watch: {
    $route () {
      this.isFilter = false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.bottom-links
  position absolute
  bottom: $spacings.md
  left: $spacings.md
  right: $spacings.md
  
  &__sound
    width 20px
</style>