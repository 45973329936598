import { storage } from '@/utils/storage'
import { scoring } from '@/assets/data'

export default {
	namespaced: true,

	state: {
		score: 0,
		isFistVisit: true,
		sounds: {
			click: '/sounds/click.mp3',
			keypoint: '/sounds/keypoint.mp3',
			result: '/sounds/result.mp3'
		},
		sound: true
	},

	getters: {
		score (state) {
			return state.score
		},
		isTutoSeen(state) {
			return state.isTutoSeen
		}
	},

	actions: {
		isTutoSeen ({ commit, state }) {
			return storage.getItem('tuto').then(res => {
        if (res !== null) {
					commit('isTutoSeen', res)
        }
        else {
					commit('isTutoSeen', false)
        }
      })
		}
	},

	mutations: {
		right (state) {
			state.score += scoring.right
		},
		wrong (state) {
			state.score += scoring.wrong
		},
    isTutoSeen (state, payload) {
      state.isTutoSeen = payload
      storage.setItem('tuto', payload)
    },
    sound (state, payload) {
      state.sound = payload
    }
	}
}