<template>
  <div class="u-wrapper-panel u-row u-top u-pad-y-md u-right">
    <div class="keypoint-question__header u-w4of12 u-w6of12--md u-w12of12--sm u-row u-pad-r-lg u-between u-pad-r-md--sm u-right--sm u-z-middle">
      <app-button
        class="keypoint-question__header__btn"
        :class="{'is-visible': !!prev, 'u-pointer-none': !prev}"
        :to="prev ? { name: 'Keypoint', params: {super: prev.slug }} : { name: 'Homepage' }"
        :second="true"
        :icon="true"
        v-if="$device.isDesktop"
      >
        <svg class="u-marg-r-sm u-fill-main-color" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#arrow-left"/>
        </svg>
        <span class="u-white">
          QUESTION PRÉCÉDENTE
        </span>
      </app-button>
      <div v-if="data.questions[currentQuestion]" class="header-indice t-h3 u-bg-main-color u-second-color u-row u-middle u-center">
        {{ data.questions[currentQuestion].globalIndex + 1 }} / {{ totalQuestions }}
      </div>
    </div>
    <div class="u-relative u-full-width u-full-height">
      <template v-for="(question, i) in data.questions">
        <transition name="t-fader">
          <div
            v-if="(isQuestion || $device.isDesktop) && currentQuestion === i"
            class="keypoint-question u-w4of12 u-w6of12--md u-w12of12--sm u-row u-top u-middle--md u-top--sm u-pad-y-lg u-pad-r-lg u-pad-x-md--sm"
          >
            <question @answer="onAnswer" @next="currentQuestion++" :data="question" />
          </div>
        </transition>
      </template>
    </div>

    <transition name="t-fader">
      <div class="keypoint-question__see u-center" v-if="!isQuestion && !$device.isDesktop">
        <app-button :main="true" @click="isQuestion = true">
          Voir questions
        </app-button>
      </div>
    </transition>
  </div>
</template>

<script>
import Question from '@/components/Question'
import AppButton from '@/components/common/AppButton'

export default {
  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    transition: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      currentQuestion: -1,
      isQuestion: false
    }
  },

  computed: {
    totalQuestions () {
      return this.$store.getters['data/totalQuestions']
    },
    prev () {
      return this.$store.getters['data/stupidPrevKeypoint'](this.$route.params.super, this.$route.params.slug)
    }
  },

  components: {
    Question
  },

  mounted () {
    this.onInit()
  },

  methods: {
    onInit () {
      this.currentQuestion = 0
      this.$ga.send('navigation', 'Keypoint', 'quizz', this.data.slug)
    },

    onAnswer ({ right, answers }) {
      if (!this.data.isCompleted) {
        if (right) {
          this.$store.commit('global/right')
        }
        else {
          this.$store.commit('global/wrong')
        }
      }

      this.$store.commit('data/completed', {
        keypoint: this.data,
        question: this.currentQuestion,
        answers: answers
      })
    },

    checkKeypointStatus () {
      if (!this.data.questions.filter(question => !question.isComplete).length) {
        this.$store.commit('data/completed', { keypoint: this.data })
      }
    }
  },

  watch: {
    currentQuestion () {
      if (this.currentQuestion >= this.data.questions.length) {
        // we mark this keypoint as completed
        this.$store.commit('data/completed', { keypoint: this.data })

        const next = this.$store.getters['data/stupidNextKeypoint'](this.$route.params.super, this.$route.params.slug)
        if (next) {
          this.$router.push({ name: 'Keypoint', params: {super: next.slug }})
        }
        else {
          this.$router.push({ name: 'End' })
        }
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'


.keypoint-question
  position absolute
  right 0
  top 0
  height 100%

  &__header
  //   position absolute
  //   top $spacings.md
  //   right $spacings.sm

    &__btn
      opacity 0
      
      &.is-visible
        opacity 1

    +mq($until: 'desktop')
      pointer-events none

  &__see
    position absolute
    bottom $spacings.sm
    width 100%
    left 0

.header-indice
  padding 10px 15px
  border-radius 30px

  +mq($until: 'desktop')
    margin-right 60px
    margin-top 5px

</style>
