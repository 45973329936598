<template>
  <div class="result u-wrapper-panel u-row u-bottom u-center">
    <!-- <transition name="t-fader">
      <div v-if="!$device.isDesktop && !isDetails" class="result__title__wrapper u-center u-z-back">
        <div class="t-h3 u-main-color">
          Tu pourrais bien être...
        </div>
        <div class="u-center u-marg-t-sm u-pad-x-md">
          <h2 class="result__title t-h2 u-bg-main-color u-inline-block">
            {{ data.shortTitle ? data.shortTitle : data.title }}
          </h2>
        </div>
      </div>
    </transition> -->

    <transition name="t-fader">
      <popin-mail v-if="!isDetails" :categories="categoriesSlug" @next="onDetails" />
    </transition>

    <!-- <transition name="t-fader">
      <div v-if="!isDetails" class="u-row u-full-width u-center u-marg-b-md">
        <app-button
          :second="true"
          :to="{ name: 'Homepage' }"
          @click="$ga.send('navigation', 'restart')"
          class="u-marg-r-sm"
        >
          RECOMMENCER
        </app-button>
        <app-button :main="true" @click="onDetails">
          VOIR RÉSULTAT
        </app-button>
      </div>
    </transition> -->

    <!-- MOBILE VERSION -->
    <transition name="t-fader--delayed">
      <app-popin
        innerClass="result__popin u-main-color u-radius u-row u-middle u-relative u-overflow-y-scroll"
        v-if="isDetails && !$device.isDesktop"
        :big="true"
      >
        <div class="result__popin__wrapper u-full-height u-relative u-overflow-y-scroll u-pad-md">
          <template v-for="i in 3">
            <h2 class="t-h2 u-full-width">
              {{ categories[i - 1].title }}
            </h2>
            <strates-category :data="categories[i - 1].content" />
            <div v-if="i !== 3" class="u-net u-full-width u-bg-main-color u-marg-y-md"></div>
          </template>
        </div>
        <div @click="isDetails = false" class="result__popin__close">
          <svg class="u-wrapper-panel u-stroke-main-color">
            <use xlink:href="#cross"/>
          </svg>
        </div>
        <div class="result__panel__item__bottom u-row u-middle u-center u-radius">
          <app-button
            url="https://content.brig.ht/les-formats-de-communication-innovants-en-2022"
            :main="true"
          >
            Nos Formats 2022
          </app-button>
        </div>
      </app-popin>
    </transition>

    <!-- DESKTOP VERSION -->
    <div v-if="isDetails && $device.isDesktop">
      <div class="result__panel u-z-back u-w4of12 u-w12of12--sm u-row u-top u-pad-r-md">
        <div class="result__panel__item u-full-width u-radius u-main-color">
          <div class="result__panel__item__wrapper u-pad-md u-left u-invisible-scroll">
            <template v-for="i in 3">
              <h2 class="t-h2 u-full-width">
                {{ categories[i - 1].title }}
              </h2>
              <strates-category :data="categories[i - 1].content" />
              <div v-if="i !== 3" class="u-net u-full-width u-bg-main-color u-marg-y-md"></div>
            </template>
          </div>
          <div class="result__panel__item__bottom u-row u-middle u-center u-radius">
            <app-button
              :url="page.cta[0].url"
              :main="true"
            >
              {{ page.cta[0].text }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import StratesCategory from '@/components/StratesCategory'
import PopinMail from '@/components/PopinMail'
import AppPopin from '@/components/common/AppPopin'

import { audio } from '@/utils/Audio'

import Page from './Page'

import { Vector3 } from 'three'

export default {
  mixins: [Page],

  components: {
    AppButton,
    StratesCategory,
    PopinMail,
    AppPopin
  },

  computed: {
    categories () {
      return this.$store.getters['data/mostChosenCategories']
    },

    categoriesSlug () {
      const categorie = [...this.$store.getters['data/mostChosenCategories']].splice(0, 3)
      return categorie.map(cat => cat.slug)
    },

    page () {
      return this.$store.getters['pages/endpage']
    },

    videoId () {
      if (!this.data || !this.data.video.length) {
        return null
      }
      return this.$device.isMobile && this.data.video[1] ? this.data.video[1].url : this.data.video[0].url
    },

    _position () {
      if (this.page.position.length) {
        return new Vector3(parseFloat(this.page.position[0].x), parseFloat(this.page.position[0].y), parseFloat(this.page.position[0].z))
      }
    },
    _look () {
      if (this.page.position.length) {
        return new Vector3(parseFloat(this.page.look[0].x), parseFloat(this.page.look[0].y), parseFloat(this.page.look[0].z))
      }
    }
  },

  data () {
    return {
      isDetails: false
    }
  },

  mounted () {
    this.$ga.send('navigation', 'end')
    this.$ga.send('gameplay', 'result', '', this.categories[0].slug)

    audio.src = this.$store.state.global.sounds.result
    audio.play()
  },

  methods: {
    onDetails () {
      this.$ga.send('navigation', 'end', 'details')
      this.isDetails = true
    }
  },

  watch: {
    isDetails (to) {
      if (to) {
        this.$hub.emit('header:hidden')
      }
      else {
        this.$hub.emit('header:visible')
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

$second-color = #011449

.result
  &__panel    
    position absolute
    right 0
    top 100px
    bottom 100px

    &__item
      background-color: rgba($colors.white, .2)
      position relative
      height 100%
      
      &__wrapper
        height 100%
        overflow-y scroll
        padding-bottom 120px !important

      &__bottom
        position absolute
        width 100%
        left 0
        bottom 0
        height 100px
        padding: 0 $spacings.md
        background-color: rgba($colors.white, .3)

        +mq($until: 'desktop')
          background-color: rgba($second-color, .9)

  &__popin
    height 100%
    background-color: rgba($colors.white, .2)

    +mq($until: 'desktop')
      background-color: rgba($second-color, .8)

    &__wrapper
      padding-bottom 120px !important

    &__close
      position absolute
      top 15px
      right 15px
      height 17px
      width 17px

  &__title
    border-radius 2em
    padding .7em 1em

    &__wrapper
      position absolute
      left 0
      top 20%
      width 100%
</style>