import axios from 'axios'
import { emitter } from '@/utils/emitter'

export class Hubspot {
  constructor (uri, token) {
    this.send = this.send.bind(this)
    this.setToken = this.setToken.bind(this)
    this.setup = this.setup.bind(this)

    this.uri = process.env.VUE_APP_HUBSPOT_APIURI
    this.config = {}

    this.setToken(process.env.VUE_APP_HUBSPOT_APIKEY)
  }

  setup () {
    this.config.headers = {
      'x-api-key': `${this.token}`
    }
  }

  send (args) {
    return axios.post(this.uri, {
      ...args,
      bright_experience: "Quiz 3D",
      bright_env: process.env.NODE_ENV,
      hubspot_owner_id: 76544859, // Abdel's id
    }, this.config)
  }

  get headers () {
    return this.config.headers
  }

  setToken (token) {
    this.token = token
    this.setup()
  }
}

export const hubspot = new Hubspot()